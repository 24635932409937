<template>
	<div class="container">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
			<el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '公司列表') }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="top">
			<el-input v-model="searchData.value"
				:placeholder="fun.getLanguageText($store.state.language, '请输入关键词进行搜索')"></el-input>
			<el-button type="primary" icon="el-icon-search" @click="getCompanyList">
				{{ fun.getLanguageText($store.state.language, '搜索') }}
			</el-button>
			<el-select v-model="searchData.companyType" :placeholder="fun.getLanguageText($store.state.language, '请选择公司类型')"
				@change="changeType">
				<el-option :label="fun.getLanguageText($store.state.language, '全部类型')" value="0"></el-option>
				<el-option :label="fun.getLanguageText($store.state.language, '管理员')" value="1"></el-option>
				<el-option :label="fun.getLanguageText($store.state.language, '客户')" value="3"></el-option>
				<el-option :label="fun.getLanguageText($store.state.language, '终端')" value="4"></el-option>
			</el-select>
			<el-select filterable v-model="searchData.country_obj" value-key="name"
				:placeholder="fun.getLanguageText($store.state.language, '请选择国家')" @change="changeCountry">
				<el-option :label="fun.getLanguageText($store.state.language, '全部')" value="0"></el-option>
				<el-option :label="$store.state.language == 1 ? item.name : item.name_en" :value="item"
					v-for="item in countryList" :key="item.id"></el-option>
			</el-select>
			<!--
      <el-select filterable v-model="searchData.province" placeholder="请选择城市" @change="changeProvince">
        <el-option label="全部" value="0"></el-option>
        <el-option :label="item.name" :value="item.id" v-for="item in provinceList" :key="item.id"></el-option>
      </el-select>
      -->
			<el-select filterable v-model="searchData.city"
				:placeholder="fun.getLanguageText($store.state.language, '请选择城市')" @change="changeCity">
				<el-option :label="fun.getLanguageText($store.state.language, '全部')" value="0"></el-option>
				<el-option :label="$store.state.language == 1 ? item.name : item.name_en" :value="item.id"
					v-for="item in cityList" :key="item.id"></el-option>
			</el-select>
			<el-button v-if="$store.state.roleId < 4" type="primary" icon="el-icon-plus" class="add-username" @click="add">
				{{
					fun.getLanguageText($store.state.language, '添加公司') }}
			</el-button>
		</div>
		<el-table :data="tableData" stripe style="width: 100%" :max-height="$store.state.clientHeight">
			<el-table-column prop="index" :label="fun.getLanguageText($store.state.language, '序号')" width="120">
			</el-table-column>
			<el-table-column prop="companyName" :label="fun.getLanguageText($store.state.language, '公司名称')">
			</el-table-column>
			<el-table-column prop="companyAddr" :label="fun.getLanguageText($store.state.language, '公司地址')">
			</el-table-column>
			<el-table-column prop="companyEmail" :label="fun.getLanguageText($store.state.language, '公司邮箱')">
			</el-table-column>
			<el-table-column prop="companyType" :label="fun.getLanguageText($store.state.language, '公司类型')">
				<template slot-scope="scope">
					{{ fun.getLanguageText($store.state.language, scope.row.companyType) }}
				</template>
			</el-table-column>
			<el-table-column :prop="$store.state.language == 1 ? 'place' : 'place_en'"
				:label="fun.getLanguageText($store.state.language, '国家/城市')">
			</el-table-column>
			<el-table-column fixed="right" :label="fun.getLanguageText($store.state.language, '设置')" width="100">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="create(scope)"
						:disabled="($store.state.roleId == 3 && scope.row.companyType == '客户') || $store.state.roleId == 4">
						{{ fun.getLanguageText($store.state.language, '编辑') }}
					</el-button>
					<el-button type="text" size="small" @click="del(scope)" :disabled="$store.state.roleId == 4">
						{{ fun.getLanguageText($store.state.language, '删除') }}
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="footer" background layout="sizes, prev, pager, next, jumper, total"
			:page-size="pageData.pageSize" :current-page="pageData.currentPage" :total="pageData.total"
			:page-sizes="pageData.pageSizes" @size-change="sizeChange" @current-change="currentChange">
		</el-pagination>
	</div>
</template>
<script>
export default {
	data() {
		return {
			router: false,
			input: '',
			user_type: '',
			tableData: [],
			searchData: { // 搜索数据
				value: '', // 查询内容
				companyType: '', // 公司类型
				country: '',
				city: '',
				country_path: '',
				country_obj: null
			},
			pageData: {
				total: 0, // 总条数
				currentPage: 1, // 当前页数
				totalPage: 0, // 总页数
				pageSize: 20, //
				pageSizes: [10, 20, 30, 50]
			},
			roleList: [],
			countryList: [],
			cityList: []
		}
	},
	created() {
		this.getCompanyList()
		this.getCountryList()
		// this.getRoleList()
	},
	methods: {
		changeType() {
			setTimeout(() => {
				this.getCompanyList()
			}, 500)
		},
		// 编辑公司
		create(e) {
			this.$router.push({
				name: "Home/UserList/AddUserList",
				query: {
					id: e.row.id
				}
			})
			console.log(e)
		},
		// 当前选中国家被修改
		changeCountry(e) {
			console.log(e)
			this.searchData.country_path = e.path
			this.searchData.country = e.id
			this.searchData.city = ''
			this.getCityList()
			this.getCompanyList()
		},
		changeCity() {
			this.getCompanyList()
		},
		// 读取城市
		getCityList() {
			this.$http.post('region/select-city', {
				path: this.searchData.country_path
			}).then(res => {
				if (res.data.msg == 'success') {
					console.log(res.data)
					this.cityList = res.data.data
				}
			}).catch(err => {

			})
		},
		// 读取国家
		getCountryList() {
			this.$http.post('region/select-country', {}).then(res => {
				if (res.data.msg == 'success') {
					console.log(res.data)
					this.countryList = res.data.data
				}
			}).catch(err => {

			})
		},
		// 读取用户类型列表
		getRoleList() {
			this.$http.post('role/list', {}).then(res => {
				if (res.data.msg == 'success') {
					this.roleList = res.data.data
					console.log(this.roleList)
				}
			}).catch(err => {
				// this.getRoleList()
			})
		},
		// 改变页码
		currentChange(e) {
			this.pageData.currentPage = e
			this.getCompanyList()
		},
		// 选择页码
		sizeChange(e) {
			this.pageData.pageSize = e
			this.getCompanyList()
		},
		// 读取公司列表
		getCompanyList() {
			this.$http.post('company/list', {
				companyType: (this.searchData.companyType === '' || this.searchData.companyType == 0) ? 0 :
					this.searchData.companyType -
					2,
				country: this.searchData.country,
				city: this.searchData.city,
				search: this.searchData.value,
				currentPage: this.pageData.currentPage,
				pageSize: this.pageData.pageSize
			}).then(res => {
				console.log(res.data)
				if (res.data.msg === 'success') {
					res.data.data.info.map((item, index) => {
						item.index = index + 1
					})
					this.tableData = res.data.data.info
					this.pageData.total = Number(res.data.data.page.total)
				}
			}).catch(err => {

			})
		},
		// 添加账号
		add() {
			this.$router.push('/Home/UserList/AddUserList')
		},
		// 删除指定账号
		del(e) {
			this.$confirm(this.fun.getLanguageText(this.$store.state.language, '此操作将永久删除该数据, 是否继续?'),
				this.fun.getLanguageText(this.$store.state.language, '提示'), {
				confirmButtonText: this.fun.getLanguageText(this.$store.state.language, '确定'),
				cancelButtonText: this.fun.getLanguageText(this.$store.state.language, '取消'),
				type: 'warning'
			}).then((res) => {
				// 执行删除操作
				return this.$http.post('company/delete', {
					id: e.row.id
				})
			}).then(res => {
				return new Promise((a, b) => {
					if (res.data.msg === 'success') {
						this.tableData.splice(e.$index, 1);
						this.$message({
							type: 'success',
							message: this.fun.getLanguageText(this.$store.state.language,
								'删除成功!')
						});
					} else {
						b(res.data.msg)
					}
				})
			}).catch((err) => {
				console.log(err)
				if (err == 'cancel') {
					err = "取消删除"
				}
				this.$message({
					type: 'info',
					message: this.fun.getLanguageText(this.$store.state.language, err)
				});
			});
		},
		// 计算高度
		getListHeight() {
			let Html = document.getElementsByClassName('box-card')
			if (Html.length > 0) {
				this.$store.state.clientHeight = Html[0].clientHeight - 131 - 60
				console.log(this.$store.state.clientHeight, Html[0].clientHeight)
			}
		}
	},

	mounted() {
		this.getListHeight()
		window.onresize = () => {
			return (() => {
				this.getListHeight()
			})();
		}
	},
	destroyed() {
		window.onresize = null;
	}
};
</script>

<style lang="less" scoped>
.el-input {
	width: 200px;
	margin-right: 10px;
}

.el-select {
	width: 180px;
	margin-left: 20px;
}

.container {
	display: flex;
	flex-direction: column;
}

.top {
	// height: 32px;
	margin-bottom: 20px;
	position: relative;
}

.add-username {
	position: absolute;
	right: 0;
}

.footer {
	display: flex;
	padding: 0;
	margin-top: 14px;
	justify-content: flex-end;
}
</style>
